import React from 'react';
import { useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import ResultsHeader from '../components/ResultsHeader';
import PropertyListings from '../components/PropertyListings';

const SearchResultsPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('location'); 
    const status = searchParams.get('status') || "For Rent";

    return (
        <div className="font-poppins">
            <header className="shadow">
                <div className="container mx-auto px-4 py-4 mt-12">
                    <h1 className="text-3xl font-bold">Betale</h1>
                </div>
            </header>
            <SearchBar />
            <ResultsHeader />
            <div className="container mx-auto px-4">
                <h2 className="text-2xl font-bold mb-4">Search Results for "{query}"</h2>
                <PropertyListings status={status} />
            </div>
        </div>
    );
}

export default SearchResultsPage;
