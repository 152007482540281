import React, { useState } from "react";
import { motion } from "framer-motion";
import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const PropertyDetailsStep = ({ nextStep, prevStep, formData, setFormData }) => {
  const [uploading, setUploading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    setUploading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        const imageRef = ref(storage, `property-images/${file.name + uuidv4()}`);
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
      });

      const imageUrls = await Promise.all(uploadPromises);
      setFormData((prevData) => ({
        ...prevData,
        image: [...(prevData.images || []), ...imageUrls],
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <motion.div
        className="py-6 px-8 w-full max-w-5xl bg-white shadow-lg rounded-lg"
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 text-center">
          Property Details
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            <div>
              <label htmlFor="propertyStatus" className="block text-gray-700 mb-2">
                Property Status
              </label>
              <div className="space-y-4">
                {["For Sale", "For Rent"].map((status) => (
                  <label key={status} className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="propertyStatus"
                      value={status}
                      checked={formData.propertyStatus === status}
                      onChange={handleInputChange}
                      className="form-radio h-5 w-5 text-black focus:ring-black"
                    />
                    <span className="text-gray-700">{status}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="price" className="block text-gray-700 mb-2">
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price || ""}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-3">
              <label htmlFor="description" className="block text-gray-700 mb-2">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description || ""}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                rows="4"
              />
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-3">
              <label htmlFor="images" className="block text-gray-700 mb-2">
                Upload Images
              </label>
              <input
                type="file"
                id="images"
                name="images"
                multiple
                onChange={handleImageUpload}
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-50 file:cursor-pointer file:bg-black file:text-white file:border-0 file:py-2 file:px-4 file:rounded-md hover:file:bg-gray-800"
              />
              {uploading && <p className="text-gray-500 mt-2">Uploading images...</p>}
              {formData.image && formData.image.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-gray-700 mb-2">Uploaded Images:</h3>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {formData.image.map((url, index) => (
                      <img key={index} src={url} alt={`Uploaded ${index + 1}`} className="w-full h-auto rounded-md shadow-md" />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-8 flex justify-between">
            <motion.button
              onClick={prevStep}
              className="bg-gray-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.3 }}
            >
              Back
            </motion.button>

            <motion.button
              type="submit"
              className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 ease-in-out"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: 0.3 }}
            >
              Next
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default PropertyDetailsStep;
