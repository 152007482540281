import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../constants";
import { getTokenFromCookies } from "../../../shared/getToken.mjs";

export const propertyApi = createApi({
  reducerPath: "propertyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromCookies();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: () => `property/`,
    }),
    getPropertyDetails: builder.query({
      query: (id) => `property/${id}`,
    }),

    getOwnerLists: builder.query({
      query: () => `property/owner`,
    }),

    getPropertyById: builder.query({
      query: (id) => ({
        url: `property/${id}`,
      }),
    }),

    addProperty: builder.mutation({
      query: (data) => ({
        url: `property/`,
        method: "POST",
        body: data,
      }),
    }),

    filterPropertiesByStatus: builder.query({
      query: (status) =>
        `property/filter?propertyStatus=${encodeURIComponent(status)}&limit=9`,
    }),

    filterPropertiesByType: builder.query({
      query: (type) => `property/filter?type=${encodeURIComponent(type)}`,
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useAddPropertyMutation,
  useGetPropertyByIdQuery,
  useFilterPropertiesByStatusQuery,
  useGetOwnerListsQuery,
  useFilterPropertiesByTypeQuery,
  useGetPropertyDetailsQuery,
} = propertyApi;
