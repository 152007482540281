import React from "react";
import PropertyListings from "../../../components/PropertyListings";
import { useSelector } from "react-redux";
import { selectFavorites } from "../slice/propertySlice";

const SavedProperties = () => {
  const favorites = useSelector(selectFavorites);

  const properties = favorites.map((property) => ({
    id: property.id,
    image: property.image,
    price: property.price.toLocaleString(),
    bed: property.bed || 0,
    bath: property.bath || 0,
    sqft: property.sqft || "N/A",
    address: `${property.address || ""}`,
    type: property.type,
  }));

  return (
    <div className="container mx-auto px-4 md:px-6 mt-8 py-12">
      {properties.length === 0 ? (
        <div className="flex justify-center items-center h-full">
          <div className="text-center">
            <p className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-600 mb-4">
              No saved properties found.
            </p>
            <p className="text-sm md:text-base text-gray-500">
              You haven't added any properties to your favorites yet.
            </p>
          </div>
        </div>
      ) : (
        <PropertyListings properties={properties} />
      )}
    </div>
  );
};

export default SavedProperties;
