import React from 'react';

const FeaturedArticle = () => {
    return (
        <section className="py-12">
            <div className="container mx-auto px-4">
                <div className="relative bg-cover bg-center h-72 rounded-lg overflow-hidden" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1560184897-ae75f418493e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHJlYWwlMjBlc3RhdGV8ZW58MHx8MHx8fDA%3D' }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="text-center">
                            <h1 className="text-white text-xl md:text-2xl font-semibold">What BetAle Offer ...</h1>
                            <button className="mt-4 bg-black text-white px-6 py-2 rounded-full">Read Article</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturedArticle;
