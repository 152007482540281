import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200;
      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = () => {
    if (location.trim()) {
      navigate(`/search-results?location=${encodeURIComponent(location)}`);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <section
      className="bg-cover bg-center h-screen flex flex-col items-center justify-center relative"
      style={{
        backgroundImage: `url(https://media.istockphoto.com/id/1481643878/photo/american-style-country-house-on-a-striking-summer-day-with-blue-sky-beautiful-residential.jpg?s=612x612&w=0&k=20&c=xDBSiWJewpbwg1pFFTITNjEAKBiARrIf9sav9gCCnpg=)`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative text-center z-10 px-4">
        <h1 className="text-white text-2xl sm:text-3xl md:text-5xl font-bold mb-6 md:mb-10">
          <span className="text-red-500 font-extrabold">Bet</span>_Ale : Site for Rent and Sale Home
        </h1>

        <div
          className={`bg-white flex items-center rounded-full px-2 py-2 w-full sm:w-4/5 md:max-w-lg mx-auto transition-transform duration-300 ${
            isScrolled
              ? "md:fixed md:top-4 md:left-1/2 md:transform md:-translate-x-1/2 md:z-50 md:shadow-lg"
              : "mt-4"
          }`}
        >
          <input
            type="text"
            placeholder="Search by address"
            className="w-full text-sm md:text-base outline-none text-gray-700 px-2 py-1 sm:px-4 sm:py-2"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <button
            onClick={handleSearch}
            className="bg-black text-white text-sm md:text-base px-4 sm:px-6 py-2 rounded-full"
          >
            Search
          </button>
        </div>
      </div>

      <div className="mt-6 z-10" id="hero-links">
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => handleNavigate("/for-rent")}
            className="font-semibold text-white underline text-lg sm:text-xl px-4 py-2 sm:py-4 active:underline"
          >
            For Rent
          </button>
          <button
            onClick={() => handleNavigate("/for-sale")}
            className="font-semibold text-white underline text-lg sm:text-xl px-4 py-2 sm:py-4 active:underline"
          >
            For Sale
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
