import React, { useState } from "react";
import LocationStep from "../components/LocationStep";
import PropertyDetailsStep from "../components/PropertyDetailsStep";
import HomeTypeStep from "../components/HomeTypeStep";
import HomeFacilitiesStep from "../components/HomeFacilitiesStep";
import { useAddPropertyMutation } from "../api/propertyApi";
import { useNavigate } from "react-router-dom";
import { selectOwnerId } from "../../authentication/slices/authSlice";
import { useSelector } from "react-redux";

const AddPropertyForSignedUser = () => {
  const navigate = useNavigate();
  const ownerId = useSelector(selectOwnerId);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    propertyStatus: "",
    price: "",
    description: "",
    images: [],
    address: "",
    absoluteLocation: {
      type: "Point",
      coordinates: [0, 0],
      address: "",
      description: "",
    },
    relativeLocation: {
      country: "",
      city: "",
      extraDetail: "",
    },
    owner: ownerId,
    bedrooms: 0,
    bathrooms: 0,
    unitNumber: "",
    floorNumber: 0,
    footTraffic: 0,
    displayWindows: false,
    leaseTerms: "",
    parkingSpaces: 0,
    buildingAmenities: [],
  });

  const [addProperty] = useAddPropertyMutation();

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmitProperty = async () => {
    try {
      const addProp = await addProperty(formData).unwrap();
      console.log("Property added successfully:", addProp);
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error("Property addition failed:", err);
    }
  };

  switch (step) {
    case 1:
      return (
        <HomeTypeStep
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 2:
      return (
        <HomeFacilitiesStep
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 3:
      return (
        <PropertyDetailsStep
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 4:
      return (
        <LocationStep
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleSubmitProperty}
        />
      );
    default:
      return null;
  }
};

export default AddPropertyForSignedUser;
