import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaHome, FaRegUser, FaRegHeart } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectFavoriteCount } from "../features/manage-property/slice/propertySlice";
import { selectIsAuthenticated } from "../features/authentication/slices/authSlice";
import logo from "../assets/logo1.png";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const favoriteCount = useSelector(selectFavoriteCount);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  console.log(isAuthenticated)
  const heartIconRef = useRef(null);

  useEffect(() => {
    console.log(isAuthenticated); // Check if it changes after an async operation
  }, [isAuthenticated]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200;
      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (heartIconRef.current) {
      heartIconRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [favoriteCount]);

  return (
    <header
      className={`bg-white shadow-md fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${
        isScrolled ? "-translate-y-full" : "translate-y-0"
      }`}
    >
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <Link to="/" className="md:block">
            <img src={logo} alt="Logo" className="h-10" />
          </Link>
          <nav className="hidden md:flex space-x-8 text-base font-medium">
            <Link
              to="/for-sale"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              For Sale
            </Link>
            <Link
              to="/for-rent"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              For Rent
            </Link>
            <Link
              to="/my-list"
              className="text-gray-700 hover:text-gray-900 transition duration-200"
            >
              My List
            </Link>
          </nav>
        </div>
        <div className="flex space-x-6 items-center">
          <div className="relative hidden md:block" ref={heartIconRef}>
            <Link to="/saved-properties">
              <FaRegHeart className="h-6 w-6 text-gray-700 hover:text-red-500 transition duration-200 cursor-pointer" />
            </Link>
            {favoriteCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                {favoriteCount}
              </span>
            )}
          </div>

          {isAuthenticated ? (
            <FaRegUser className="h-6 w-6 text-gray-700 hover:text-gray-900 transition duration-200 hidden md:block" />
          ) : (
            <Link to="/signin" className="hidden md:block">
              <button className="text-gray-700 text-base font-semibold hover:text-gray-900 transition duration-200">
                Sign in
              </button>
            </Link>
          )}

          {!isAuthenticated ? (
            <Link
              to="/add-property"
              className="bg-black text-white font-semibold px-4 py-2 rounded-lg shadow transition duration-200"
            >
              List Property
            </Link>
          ) : (
            <Link
              to="/add-more"
              className="bg-black text-white font-semibold px-4 py-2 rounded-lg shadow transition duration-200"
            >
              Add More
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
