import React, { useState } from "react";
import LocationStep from "../components/LocationStep";
import PropertyDetailsStep from "../components/PropertyDetailsStep";
import HomeTypeStep from "../components/HomeTypeStep";
import HomeFacilitiesStep from "../components/HomeFacilitiesStep";
import SignupModal from "../../authentication/pages/SignupModal";
import { useAddPropertyMutation } from "../api/propertyApi";
import { useSignupMutation } from "../../authentication/api/authApi";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../authentication/slices/authSlice";
import { useDispatch } from "react-redux";

const AddProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    propertyStatus: "",
    price: "",
    description: "",
    images: [],
    address: "",
    absoluteLocation: {
      type: "Point",
      coordinates: [0, 0],
      address: "",
      description: "",
    },
    relativeLocation: {
      country: "",
      city: "",
      extraDetail: "",
    },
    owner: "",
    bedrooms: 0,
    bathrooms: 0,
    unitNumber: "",
    floorNumber: 0,
    footTraffic: 0,
    displayWindows: false,
    leaseTerms: "",
    parkingSpaces: 0,
    buildingAmenities: [],
  });
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const [signup] = useSignupMutation();
  const [addProperty] = useAddPropertyMutation();

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSignup = async (signupData) => {
    try {
      const signupRes = await signup(signupData).unwrap();
      console.log("signup ...", signupRes);
      dispatch(setToken(signupRes));
      const userId = signupRes.data.newOwner._id;
      setIsSignupModalOpen(false);
      const propertyData = { ...formData, owner: userId };

      const addProp = await addProperty(propertyData).unwrap();
      console.log("add ...", addProp);
      console.log("Property added successfully");
      navigate("/");
      window.location.reload();
    } catch (err) {
      console.error("Signup or Property addition failed:", err);
    }
  };

  const handleSignupModalClose = () => setIsSignupModalOpen(false);

  switch (step) {
    case 1:
      return (
        <HomeTypeStep
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 2:
      return (
        <HomeFacilitiesStep
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 3:
      return (
        <PropertyDetailsStep
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 4:
      return (
        <>
          <LocationStep
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
            openModal={() => setIsSignupModalOpen(true)}
          />
          <SignupModal
            isOpen={isSignupModalOpen}
            onClose={handleSignupModalClose}
            onSubmit={handleSignup}
          />
        </>
      );
    default:
      return null;
  }
};

export default AddProperty;
