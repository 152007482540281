import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./features/authentication/api/authApi";
import { propertyApi } from "./features/manage-property/api/propertyApi";
import authSliceReducer from "./features/authentication/slices/authSlice";
import propertySliceReducer from "./features/manage-property/slice/propertySlice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [propertyApi.reducerPath]: propertyApi.reducer,
    auth: authSliceReducer,
    property: propertySliceReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, propertyApi.middleware),
});

setupListeners(store.dispatch);
