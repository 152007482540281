import React, { useState } from "react";
import { FaSearch, FaHeart, FaBell, FaRegUser, FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileBottomNav = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const navItems = [
    { icon: FaHome, to: "/" },
    { icon: FaSearch, to: "/" },
    { icon: FaHeart, to: "/saved-properties" }, 
    { icon: FaRegUser, to: "/signin" }, 
  ];

  return (
    <nav className="fixed bottom-4 left-6 right-6 bg-black rounded-2xl p-2 md:hidden flex justify-around items-center shadow-lg border-t border-gray-800">
      {navItems.map((item, index) => {
        const Icon = item.icon;
        return (
          <Link
            key={index}
            to={item.to}
            onClick={() => setActiveIndex(index)}
            className={`${
              activeIndex === index ? "text-red-500" : "text-white"
            } flex justify-center items-center p-2`}
          >
            <Icon size={24} />
          </Link>
        );
      })}
    </nav>
  );
};

export default MobileBottomNav;
