import React from "react";
import { useFilterPropertiesByStatusQuery } from "../api/propertyApi";
import PropertyListings from "../../../components/PropertyListings";
import PropertyCardSkeleton from "../../../shared/PropertyCardSkeleton";


const ForRent = () => {
  const { data: response, isLoading, error } = useFilterPropertiesByStatusQuery("For Rent");

  const properties = response?.data?.map((property) => ({
    id: property._id,
    image: property.image[0], 
    status: `New • ${new Date(property.createdAt).toLocaleTimeString()}`,
    price: property.price.toLocaleString(),
    bed: property.rentDetail?.bedrooms || 0, 
    bath: property.rentDetail?.bathrooms || 0, 
    sqft: property.rentDetail?.squareFootage || "N/A", 
    address: `${property.relativeLocation.city}, ${property.relativeLocation.country}`,
    type: property.type,
  })) || [];

  return (
    <div className="container mx-auto px-4 py-12">
      {/* <h2 className="text-3xl md:text-4xl py-6 md:py-8 font-bold text-center mb-10 md:mb-12 text-gray-800">
        
      </h2> */}

      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Array(6).fill(null).map((_, index) => (
            <PropertyCardSkeleton key={index} />
          ))}
        </div>
      ) : error ? (
        <div className="text-center text-red-500">Error fetching properties.</div>
      ) : (
        <PropertyListings properties={properties} />
      )}
    </div>
  );
};

export default ForRent;
