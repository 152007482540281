import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectFavorites, toggleFavorite, removeFavorite } from "../features/manage-property/slice/propertySlice";

const PropertyCard = ({ property }) => {
  const dispatch = useDispatch();
  const favorites = useSelector(selectFavorites);

  const isFavorited = favorites.some((fav) => fav.id === property.id);

  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    if (isFavorited) {
      dispatch(removeFavorite(property.id));
    } else {
      dispatch(toggleFavorite(property));
    }
    window.dispatchEvent(new Event("favoriteUpdated"));
  };

  return (
    <div key={property.id} className="border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow relative h-auto">
      <div className="relative">
        <Link to={`/home-detail/${property.id}`}>
          <img src={property.image} alt="Property" className="w-full h-48 object-cover" />
        </Link>
        {property.status && (
          <span className="absolute top-2 left-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
            {property.status}
          </span>
        )}
        <button
          onClick={handleToggleFavorite}
          className="absolute top-2 right-2 "
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill={isFavorited ? "red" : "black"} viewBox="0 0 24 24" stroke={isFavorited ? "red" : "white"} strokeWidth="3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg>
        </button>
      </div>
      <div className="p-4">
        <div className="text-red-500 text-sm mb-1">{property.type}</div>
        <div className="text-lg md:text-xl font-semibold mb-1">{property.price} ETB</div>
        <div className="text-sm mb-1">
          {property.bed} bed {property.bath} bath • {property.sqft} sqft
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
