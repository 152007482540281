import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

// Helper function to parse JSON from localStorage safely
const parseLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(`Error parsing JSON from localStorage key "${key}":`, error);
    return null;
  }
};

const initialState = {
  token: Cookies.get("token") || null,
  isAuthenticated: parseLocalStorageItem("isAuthenticated") || false,
  ownerId: parseLocalStorageItem("ownerId") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      console.log(action.payload.data)
      state.token = action.payload.data.token;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("ownerId", JSON.stringify(action.payload.data.owner._id));
      Cookies.set("token", state.token);
    },
    logout: (state) => {
      state.token = null;
      localStorage.setItem("isAuthenticated", false);
      localStorage.removeItem("ownerId");
      Cookies.remove("token");
    },
  },
});

export const { setToken, logout } = authSlice.actions;

export default authSlice.reducer;
export const selectToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectOwnerId = (state) => state.auth.ownerId;
