import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useGetPropertyDetailsQuery } from "../features/manage-property/api/propertyApi";

const CustomPrevButton = () => (
  <button className="absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-black text-white rounded-full shadow-lg z-20 hover:bg-gray-700 transition duration-200">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
    </svg>
  </button>
);

const CustomNextButton = () => (
  <button className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-black text-white rounded-full shadow-lg z-20 hover:bg-gray-700 transition duration-200">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
    </svg>
  </button>
);

const HomeDetail = () => {
  const { id } = useParams();
  const { data: property, isLoading, error } = useGetPropertyDetailsQuery(id);
  const [showPhone, setShowPhone] = useState(false);

  const images = property?.data.image || [];
  const items = images.map((image, index) => (
    <img
      key={index}
      src={image}
      alt={`Property Image ${index + 1}`}
      className="w-full h-64 md:h-96 object-cover rounded-md"
    />
  ));

  return (
    <div className="container mx-auto px-4 py-12 relative">
      {isLoading && (
        <div className="flex justify-center items-center py-12">
          <div className="text-center">
            <svg
              className="animate-spin h-10 w-10 text-red-500 mx-auto mb-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <p className="text-xl text-gray-600">Loading property details...</p>
          </div>
        </div>
      )}

      {error && (
        <div className="text-center text-red-500 py-12">
          Error fetching property details.
        </div>
      )}

      {!isLoading && !error && (
        <>
          {/* Carousel */}
          <div className="relative mt-12 mb-6">
            <AliceCarousel
              items={items}
              autoPlay
              autoPlayInterval={3000}
              infinite
              disableDotsControls
              renderPrevButton={() => <CustomPrevButton />}
              renderNextButton={() => <CustomNextButton />}
            />
            {/* Badge Positioned on Top-Left of the Carousel Image */}
            <span className="absolute top-4 left-4 bg-red-500 text-white text-xs font-bold px-3 py-1 rounded-full shadow-lg z-20">
              New • {new Date(property?.data.createdAt).toLocaleDateString()}
            </span>
          </div>

          {/* Thumbnails */}
          <div className="mt-6 flex space-x-4 overflow-x-auto">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Interior ${index + 1}`}
                className="w-50 md:w-50 h-24 md:h-32 object-cover rounded-lg flex-shrink-0 transition-transform transform hover:scale-105"
              />
            ))}
          </div>

          {/* Property Details */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6 bg-white shadow-lg rounded-lg p-6">
            <div>
              <span className="text-red-400 text-sm uppercase tracking-wide">
                {property?.data.type}
              </span>
              <h2 className="text-3xl md:text-4xl font-semibold mt-2">
                {property?.data.price?.toLocaleString()} ETB
              </h2>
              <div className="flex items-center justify-between mt-2">
                <p className="text-gray-700 text-lg">
                  Est. <span className="font-bold">2,115 ETB/mo</span>
                </p>
                <button
                  onClick={() => setShowPhone(!showPhone)}
                  className={`px-4 py-2 text-sm rounded-md shadow-lg transition duration-200 ${
                    showPhone
                      ? "bg-white text-black border border-gray-300 hover:bg-gray-100"
                      : "bg-black text-white hover:bg-red-700"
                  }`}
                >
                  {showPhone ? (
                    <span className="text-lg font-semibold">09345555</span>
                  ) : (
                    "Show Phone"
                  )}
                </button>
              </div>
              <p className="mt-3 text-lg text-gray-900">
                {property?.data.rentDetail?.bedrooms || 0} bed •
                {property?.data.rentDetail?.bathrooms || 0} bath •
                {property?.data.rentDetail?.squareFootage || "N/A"} sqft
              </p>
              <p className="text-gray-700 mt-2">
                {property?.data.relativeLocation?.city}
                {property?.data.relativeLocation?.country}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeDetail;
