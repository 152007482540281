import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites: JSON.parse(localStorage.getItem("favorites")) || [],
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    toggleFavorite: (state, action) => {
      const property = action.payload;
      const exists = state.favorites.some((fav) => fav.id === property.id);

      if (!exists) {
        state.favorites.push(property);
      }

      localStorage.setItem("favorites", JSON.stringify(state.favorites));
    },
    removeFavorite: (state, action) => {
      const propertyId = action.payload;
      state.favorites = state.favorites.filter((fav) => fav.id !== propertyId);
      localStorage.setItem("favorites", JSON.stringify(state.favorites));
    },
    loadFavorites: (state) => {
      state.favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    },
  },
});

export const { toggleFavorite, removeFavorite, loadFavorites } =
  propertySlice.actions;

export default propertySlice.reducer;
export const selectFavorites = (state) => state.property.favorites;
export const selectFavoriteCount = (state) => state.property.favorites.length;
