import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import BrowseHomes from "./components/BrowseHomes";
import ServiceSection from "./components/ServiceSection";
import FeaturedArticle from "./components/FeaturedArticle";
import Footer from "./components/Footer";
import MobileBottomNav from "./components/MobileBottomNav";
import SearchResults from "./pages/SearchResults";
import HomeDetail from "./pages/HomeDetail";
import AddProperty from "./features/manage-property/pages/AddProperty";
import Signin from "./features/authentication/pages/Signin";
import ScrollToTop from "./shared/ScrollToTop";
import ForSale from "./features/manage-property/pages/ForSale";
import ForRent from "./features/manage-property/pages/ForRent";
import MyList from "./features/manage-property/pages/MyList";
import PropertyByType from "./features/manage-property/pages/PropertyByType";
import SavedProperties from "./features/manage-property/pages/SavedProperties";
import AddPropertyForSignedUser from "./features/manage-property/pages/AddPropertyForSignedUser";

function App() {
  const location = useLocation();

  const showHeaderAndFooter = location.pathname !== "/add-property" && location.pathname !== "/add-more";

  return (
    <div className="flex flex-col min-h-screen font-poppins">
      {showHeaderAndFooter && <Header />}
      
      <div className="flex-grow">
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <BrowseHomes />
                {/* <ServiceSection /> */}
                <FeaturedArticle />
              </>
            }
          />
          <Route path="/for-sale" element={<ForSale />} />
          <Route path="/for-rent" element={<ForRent />} />
          <Route path="/saved-properties" element={<SavedProperties />} />
          <Route path="/my-list" element={<MyList />} />
          <Route path="/properties/:type" element={<PropertyByType />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/home-detail/:id" element={<HomeDetail />} />
          <Route path="/add-property" element={<AddProperty />} />
          <Route path="/add-more" element={<AddPropertyForSignedUser />} />
          <Route path="/signin" element={<Signin />} />
        </Routes>
      </div>
      
      {showHeaderAndFooter && <Footer />}
      
      {showHeaderAndFooter && <MobileBottomNav />}
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
