import React from "react";
import logo from "../assets/logo-footer2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <Link to="/">
              <img src={logo} alt="Logo" className="h-8 mb-4" />
            </Link>
            <p className="text-gray-400">Find out how the Betale works for Seller and Buyer</p>
          </div>
          <div>
            <h3 className="font-semibold mb-4">Learn About Betale</h3>
            <ul>
              <li className="mb-2"><a href="." className="text-gray-400">About Betale</a></li>
              <li className="mb-2"><a href="." className="text-gray-400">Agent vs Owner</a></li>
              <li className="mb-2"><a href="." className="text-gray-400">Commitment to Excellence</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-4">For Homeowners</h3>
            <ul>
              <li className="mb-2"><a href="." className="text-gray-400">Look for the Betale</a></li>
              <li className="mb-2"><a href="." className="text-gray-400">Find a Buyer</a></li>
              <li className="mb-2"><a href="." className="text-gray-400">Real Estate Today</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-4">For Renters</h3>
            <ul>
              <li className="mb-2"><a href="." className="text-gray-400">Create personalized social media content with Photofy</a></li>
              <li className="mb-2"><a href="." className="text-gray-400">Betale operating principles</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-400">
          <p>©YAI 2024. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
