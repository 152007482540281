import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { motion } from "framer-motion";
import "leaflet/dist/leaflet.css";

const LocationStep = ({ prevStep, formData, setFormData, openModal }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (
      name.startsWith("absoluteLocation") ||
      name.startsWith("relativeLocation")
    ) {
      const [parent, key] = name.split(".");

      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [key]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleLatitudeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      absoluteLocation: {
        ...prevData.absoluteLocation,
        coordinates: [e.target.value, prevData.absoluteLocation.coordinates[1]],
      },
    }));
  };

  const handleLongitudeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      absoluteLocation: {
        ...prevData.absoluteLocation,
        coordinates: [prevData.absoluteLocation.coordinates[0], e.target.value],
      },
    }));
  };

  const position = [
    formData.absoluteLocation?.coordinates[0] || 7.6731,
    formData.absoluteLocation?.coordinates[1] || 36.8356,
  ];

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 relative">
      <motion.div
        className="py-6 px-6 w-full max-w-5xl bg-white shadow-2xl rounded-lg grid grid-cols-1 lg:grid-cols-2 gap-6 z-10 relative"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {/* Form Section on the Left */}
        <div className="order-2 lg:order-1">
          <h2 className="text-lg font-semibold mb-4 text-center lg:text-left">
            Location Details
          </h2>

          <div className="grid grid-cols-1 gap-4">
            <div>
              <label htmlFor="address" className="block text-gray-600 mb-1">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter Address"
                value={formData.address || ""}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="latitude" className="block text-gray-600 mb-1">
                  Latitude
                </label>
                <input
                  type="number"
                  step="any"
                  id="latitude"
                  name="absoluteLocation.coordinates[0]"
                  placeholder="Latitude"
                  value={formData.absoluteLocation?.coordinates[0] || ""}
                  onChange={handleLatitudeChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div>
                <label htmlFor="longitude" className="block text-gray-600 mb-1">
                  Longitude
                </label>
                <input
                  type="number"
                  step="any"
                  id="longitude"
                  name="absoluteLocation.coordinates[1]"
                  placeholder="Longitude"
                  value={formData.absoluteLocation?.coordinates[1] || ""}
                  onChange={handleLongitudeChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="absoluteLocation.address"
                className="block text-gray-600 mb-1"
              >
                Absolute Address
              </label>
              <input
                type="text"
                id="absoluteLocation.address"
                name="absoluteLocation.address"
                placeholder="Enter Absolute Address"
                value={formData.absoluteLocation?.address || ""}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div>
              <label
                htmlFor="absoluteLocation.description"
                className="block text-gray-600 mb-1"
              >
                Description
              </label>
              <input
                type="text"
                id="absoluteLocation.description"
                name="absoluteLocation.description"
                placeholder="Enter Description"
                value={formData.absoluteLocation?.description || ""}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-6 flex justify-between items-center">
            <button
              onClick={prevStep}
              className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-gray-800 transition duration-150"
            >
              Back
            </button>

            <button
              onClick={openModal}
              className="bg-red-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-red-600 transition duration-150"
            >
              Submit
            </button>
          </div>
        </div>

        <div className="order-1 lg:order-2 flex items-center justify-center">
          <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={false}
            className="w-full h-auto rounded-lg shadow-lg z-0"
            style={{ height: "300px", width: "100%" }} 
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>
                {formData.absoluteLocation?.address || "Selected Location"}
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </motion.div>
    </div>
  );
};

export default LocationStep;
