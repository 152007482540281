import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
  apiKey: "AIzaSyBRa2J65TZcODDgocV6k4KYFt1a2cq5F84",
  authDomain: "dawaafinder-files.firebaseapp.com",
  projectId: "dawaafinder-files",
  storageBucket: "dawaafinder-files.appspot.com",
  messagingSenderId: "542945999148",
  appId: "1:542945999148:web:f446242e572df9fa49d489",
  measurementId: "G-96282P2RR3",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
