import React from "react";

const PropertyCardSkeleton = () => {
  return (
    <div className="border rounded-lg overflow-hidden shadow-md relative h-auto mt-12 bg-gray-200 animate-pulse">
      <div className="w-full h-48 bg-gray-300"></div>
      <div className="p-4">
        <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
        <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-2/3 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-1/3"></div>
      </div>
    </div>
  );
};

export default PropertyCardSkeleton;
