import React from "react";

const ResultsHeader = () => {
  return (
    <div className="my-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <h2 className="text-2xl font-semibold">
            Homes for sale in Jimma ,Oromia with newest listings
          </h2>
          <p className="text-gray-600">164 Homes</p>
          <div className="mt-2 md:mt-0">
            <label className="mr-2 text-gray-600">Sort by</label>
            <select className="border rounded-full px-4 py-2">
              <option>Newest listings</option>
              <option>Price (Low to High)</option>
              <option>Price (High to Low)</option>
              <option>Relevant listings</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsHeader;
