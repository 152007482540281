import React from "react";

const SearchBar = () => {
  return (
    <div className="bg-white shadow-md p-4 flex flex-wrap items-center justify-between space-y-4 md:space-y-0">
      <div className="flex items-center w-full md:w-auto">
        <input
          type="text"
          value="Jimma ,Oromia"
          className="border rounded-full px-4 py-2 w-full md:w-auto md:flex-grow md:mr-4"
        />
        <button className="bg-gray-200 rounded-full px-4 py-2 hidden md:block">
          X
        </button>
      </div>
      <div className="flex flex-wrap space-x-2 space-y-2">
        <button className="bg-white border border-gray-300 rounded-full px-4 py-2">
          Price
        </button>
        <button className="bg-white border border-gray-300 rounded-full px-4 py-2">
          Property type
        </button>
        <button className="bg-white border border-gray-300 rounded-full px-4 py-2">
          Rooms
        </button>
        <button className="bg-white border border-gray-300 rounded-full px-4 py-2">
          Listing status
        </button>
        <button className="bg-white border border-gray-300 rounded-full px-4 py-2">
          More
        </button>
        <button className="bg-black text-white rounded-full px-4 py-2">
          Save search
        </button>
      </div>
      <div className="flex justify-end w-full md:w-auto">
        <button className="bg-gray-100 border border-gray-300 rounded-full px-4 py-2">
          List
        </button>
        <button className="ml-2 bg-gray-100 border border-gray-300 rounded-full px-4 py-2">
          Map
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
